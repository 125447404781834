
import { computed, defineComponent, ref } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import { styleVars } from '@/compositions/styleVariables'

export default defineComponent({
  components: { TmTable },
  setup() {
    const { sizes } = styleVars
    const { activeBreakpoints, breakpointRules } = useBreakpoints()
    const breakpointToTest = ref(breakpointRules[0])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name' },
      { text: 'Min width', value: 'min' },
      { text: 'Max width', value: 'max', format: (val: string) => val ? (+val - 0.02).toString() : '' },
    ])
    const computedSizes = computed(() => Object.keys(sizes).map((key: string, i: number) => {
      return {
        name: key,
        min: sizes[key],
        max: Object.values(sizes)[i + 1],
      }
    }))

    return {
      activeBreakpoints,
      tableHeaders,
      breakpointToTest,
      breakpointRules,
      computedSizes,
    }
  },
})
